import {initializeApp} from 'https://www.gstatic.com/firebasejs/10.3.1/firebase-app.js';
import {getAnalytics} from 'https://www.gstatic.com/firebasejs/10.3.1/firebase-analytics.js';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  linkWithPopup,
  signOut,
} from 'https://www.gstatic.com/firebasejs/10.3.1/firebase-auth.js';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC0ytYCiRyW-t7emU8nkIxtZva7ae98ujc',
  authDomain: 'crosscupmanager-43eee.firebaseapp.com',
  projectId: 'crosscupmanager-43eee',
  storageBucket: 'crosscupmanager-43eee.appspot.com',
  messagingSenderId: '355297333887',
  appId: '1:355297333887:web:3ee9ff1dd3ef911d5f254a',
  measurementId: 'G-B545DSP8DZ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const analytics = getAnalytics(app);

// Initialize Firebase Authentication

// Create an instance of the Google provider
const googleProvider = new GoogleAuthProvider();

const signInButton = document.getElementById('login-button-google');
const loginButton = document.getElementById('login-button');

if (signInButton) {
  signInButton.addEventListener('click', () => {
  // Sign in with Google popup
    signInWithPopup(auth, googleProvider)
        .then((result) => {
          // Signed in successfully
          const user = result.user;
          // Check if the user is already linked with another provider
        })
        .catch((error) => {
          if (error.code == 'auth/account-exists-with-different-credential') {
            alert('U email is al gekoppeld aan een Facebook account. Log in met Facebook.');
          }
          // Handle sign-in errors
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Sign-in error: ${errorMessage}`);
        });
  });
}

const signInButtonFacebook = document.getElementById('login-button-facebook');


const facebookProvider = new FacebookAuthProvider();
if (signInButtonFacebook) {
  signInButtonFacebook.addEventListener('click', () => {
  // Sign in with Facebook popup
    signInWithPopup(auth, facebookProvider)
        .then((result) => {
          // Signed in successfully
          const user = result.user;
          // Check if the user is already linked with another provider
        })
        .catch((error) => {
          if (error.code == 'auth/account-exists-with-different-credential') {
            alert('U email is al gekoppeld aan een Google account. Log in met Google.');
          }
          // Handle sign-in errors
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`Sign-in error: ${errorMessage}`);
        });
  });
}


const signOutButton = document.getElementById('logout-button');
const loggedInUser = document.getElementById('logged-in-user');
const username = document.getElementById('username');
const popup = document.getElementById('popup');
// Event listener for the sign-out button
if (signOutButton) {
  signOutButton.addEventListener('click', () => {
  signOut(auth)
      .then(() => {
      // Signed out successfully
      // console.log("Signed out successfully");

      })
      .catch((error) => {
      // Handle sign-out errors
        const errorMessage = error.message;
        console.error(`Sign-out error: ${errorMessage}`);
      });
});
}
// Add an event listener to check the user's authentication state
auth.onAuthStateChanged((user) => {
  if (user) {
    // User is signed in.
    // const uid = user.uid; // User's unique ID
    // const displayName = user.displayName; // User's display name
    const email = user.email; // User's email address
    // const photoURL = user.photoURL; // User's photo URL
    // const emailVerified = user.emailVerified; // User's email verification status
    if (popup) {
      popup.style.display = 'none';
    }
    if (loginButton) {
      loginButton.style.display = 'none';
    }
    if (signOutButton) signOutButton.style.display = 'block';
    if (loggedInUser) loggedInUser.style.display = 'block';
    if (username) username.textContent = email;
    // console.log(`User is signed in as ${displayName} (ID: ${uid})`);
  } else {
    // User is signed out.
    if (popup) {
      popup.style.display = 'block';
    }
    if (signOutButton) signOutButton.style.display = 'none';
    if (loginButton) {
      loginButton.style.display = 'flex';
    }
    if (loggedInUser) loggedInUser.style.display = 'none';
    // console.log('User is signed out');
  }
});


export {app, auth}; // Export 'app' and 'auth' objects

